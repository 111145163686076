<template>
<b-card>

    <validation-observer ref="simpleRules">
        <b-form>
            <!-- Section Informations -->
            <h4>{{$t('Informations')}}</h4>
            <span class="font-weight-bold">{{$t('The fields with (*) are required')}}</span>
            <hr class="mb-2">
            <b-row>

                <!-- Field: Name -->
                <b-col cols="12" md="4">
                    <b-form-group :label="$t('Name')+'*'" label-for="name">
                        <validation-provider #default="{ errors }" name="name" rules="required|min:3">
                            <b-form-input id="name" v-model="driverData.name" />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                </b-col>

                <!-- Field: nickname -->
                <b-col cols="12" md="4">
                    <b-form-group :label="$t('Nickname')" label-for="nickname">
                        <b-form-input id="nickname" v-model="driverData.nickName" />
                    </b-form-group>
                </b-col>

                <!-- Field: Customer -->
                <b-col  cols="12" md="4">
                    <b-form-group :label="$t('Customer')" label-for="customer">
                            <vue-autosuggest v-if="!driverData.id" v-model="searchQuery" :suggestions="filteredOptions" :input-props="inputProps" @selected="selectHandler" @input="onInputChange">
                                <template slot-scope="{suggestion}">
                                    <span class="my-suggestion-item">{{ suggestion.item.name }}</span>
                                </template>
                            </vue-autosuggest>

                            <b-form-input v-if="driverData.id" id="customer" v-model="searchQuery" disabled />
                    </b-form-group>
                </b-col>

                <!-- Field: Card Identifier -->
                <b-col cols="12" md="4">
                    <b-form-group :label="$t('Identifier Card')" label-for="identifier-card">
                        <b-form-input id="identifier-card" v-model="driverData.cardIdentifier" />
                    </b-form-group>
                </b-col>

                <!-- Field: Document -->
                <b-col cols="12" md="4">
                    <b-form-group :label="$t('Document')+'*'" label-for="document">
                        <validation-provider #default="{ errors }" name="document" rules="required|min:10">
                            <b-form-input id="document" v-model="driverData.document" />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                </b-col>

                <!-- Field: Birth Date -->
                <b-col xl="4" md="4" sm="12">
                    <b-form-group :label="$t('Birth Date')+'*'" label-for="birth-date">
                        <validation-provider #default="{ errors }" name="birth-date" rules="required">
                            <flat-pickr id="birth-date" v-model="driverData.birthDate" class="form-control" />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                </b-col>


                <!-- Field: LicenseType -->
                <b-col cols="12" md="4">
                    <b-form-group :label="$t('License Type')+'*'" label-for="license-type">
                        <validation-provider #default="{ errors }" name="license-type" rules="required">
                            <b-form-input id="license-type" v-model="driverData.licenseType" />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                </b-col>

                <!-- Field: isCorporate -->
                <b-col cols="12" md="4">
                    <b-form-group :label="$t('Is Corporate')+'*'" label-for="is-corporate">
                        <v-select v-model="driverData.isCorporate" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="isCorporateOptions" :reduce="val => val.value" :clearable="false" input-id="is-corporate" />
                    </b-form-group>
                </b-col>

                <!-- Field: Notes -->
                <b-col cols="12" md="12">
                    <b-form-group :label="$t('Notes')" label-for="notes">
                        <b-form-textarea v-model="driverData.note" id="notes" rows="5" />
                    </b-form-group>
                </b-col>

                <!-- Btn: Save -->
                <b-col v-if="!user.role.endsWith('VI')" cols="12">
                    <b-button variant="primary" type="submit" @click.prevent="validationForm">
                        {{$t('Save')}}
                    </b-button>
                </b-col>
            </b-row>

        </b-form>
    </validation-observer>
</b-card>
</template>

<script>
import {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,
} from 'bootstrap-vue'
import {
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    BCardText,
    BFormTextarea,
} from 'bootstrap-vue'
import {
    ref,
    onUnmounted
} from '@vue/composition-api'
import router from '@/router'
import store from '@/store'
import driverStoreModule from '../driverStoreModule'

import Cleave from 'vue-cleave-component'
import 'cleave.js/dist/addons/cleave-phone.br'

import vSelect from 'vue-select'

import flatPickr from 'vue-flatpickr-component'

import {
    VueAutosuggest
} from 'vue-autosuggest'


import {
    BFormDatepicker
} from 'bootstrap-vue'

import {
    ValidationProvider,
    ValidationObserver
} from 'vee-validate'
import {
    required,
    email,
    confirmed,
    url,
    between,
    alpha,
    integer,
    password,
    min,
    digits,
    alphaDash,
    length,
} from '@validations'




export default {
    components: {
        BTab,
        BTabs,
        BCard,
        BAlert,
        BLink,

        ValidationProvider,
        ValidationObserver,
        BFormInput,
        BFormGroup,
        BForm,
        BRow,
        BCol,
        BButton,
        BCardText,
        BFormTextarea,

        Cleave,
        vSelect,
        BFormDatepicker,
        VueAutosuggest,
        flatPickr

        

    },
    data() {
        return {
            required,
            email,
            confirmed,
            url,
            between,
            alpha,
            integer,
            password,
            min,
            digits,
            alphaDash,
            length,

            datasuggest: [],
            filteredOptions: [],
            inputProps: {
                id: 'autosuggest__input',
                class: 'form-control',
                placeholder: "Search for your customer ...",
            },
            limit: 3,
            selected: null,

            isCorporateOptions: [{
                    label: 'Yes',
                    value: true
                },
                {
                    label: 'No',
                    value: false
                },

            ],

           
        }
    },
    
    methods: {

        

        msgDialog(msg, icon) {
            this.$swal({
                position: 'top-end',
                icon: icon,
                title: msg,
                showConfirmButton: false,
                timer: 1500 * 3,
                customClass: {
                    confirmButton: 'btn btn-primary',
                },

                buttonsStyling: false,
            })
        },


        validationForm() {
            this.$refs.simpleRules.validate().then(success => {
                if (success) {
                    //this.driverData.birthDate = this.adjustDate(this.driverData.birthDate)
                    if (this.driverData.id === "") {
                        store.dispatch('app-driver/addDriver', {
                                driverData: this.driverData
                            })
                            .then(response => {
                                //alert('Error 404')
                                this.msgDialog(this.$t('Successful registration'), 'success')
                                router.push({
                                    name: 'list-driver'
                                })

                            })
                            .catch(error => {
                                this.msgDialog(this.$t('Failed to save'), 'error')
                                // TODO: FAZER MSG ESPESSIFICA PARA CADAS ERRO DA API
                            })
                    } else {
                        
                        store.dispatch('app-driver/updateDriver', {

                                driverData: this.driverData
                            })
                            .then(response => {
                                //alert('Error 404')
                                this.msgDialog(this.$t('Successful update'), 'success')
                                router.push({
                                    name: 'list-driver'
                                })
                               

                            })
                            .catch(error => {
                                this.msgDialog(this.$t('FAILURE to update'), 'error')

                            })
                    }
                }
            })
        },

        selectHandler(option) {
            this.selected = option.item
            this.filteredOptions = []
            this.searchQuery = option.item.name
            this.driverData.customerId = option.item.id
        },

        onInputChange(text) {
            if (text === '' || text === undefined || text.length < 3) {

                return
            }

            store.dispatch('app-driver/fetchCustomers', {
                    q: this.searchQuery,

                })
                .then(response => {

                    this.filteredOptions = [{
                        data: response.data.data,
                    }]

                })
                .catch(error => {

                    console.log(error)

                })
        },
        suggestionSelected() {
            console.log('Selecionado', this.selected)
        }

        
    },
    setup() {
        const driverData = ref(null)

        const searchQuery = ref('')

        const DRIVER_APP_STORE_MODULE_NAME = 'app-driver'

      const user = localStorage.userData != undefined ? JSON.parse(localStorage.userData) : {}

        // Register module
        if (!store.hasModule(DRIVER_APP_STORE_MODULE_NAME)) store.registerModule(DRIVER_APP_STORE_MODULE_NAME, driverStoreModule)

        // UnRegister on leave
        onUnmounted(() => {
            if (store.hasModule(DRIVER_APP_STORE_MODULE_NAME)) store.unregisterModule(DRIVER_APP_STORE_MODULE_NAME)
        })

        if (router.currentRoute.params.id != undefined) {

            store.dispatch('app-driver/fetchDriver', {
                    id: router.currentRoute.params.id
                })
                .then(response => {
                    driverData.value = response.data

                    store.dispatch('app-driver/fetchCustomer', {
                            //id: router.currentRoute.params.id
                            id: response.data.customerId
                        })
                        .then(res => {
                            searchQuery.value = res.data.name;
                            
                        })
                        .catch(error => {

                        })
                })
                .catch(error => {
                    if (error.response.status === 404) {
                        //driverData.value = undefined
                    }
                })
        } else {
            driverData.value = {
                id:'',
                name: '',
                customerId: null,
                customerName: '',
                document: '',
                birthDate: '',
                licenseType: '',
                note: '',
                isCorporate: false,
                nickName: '',
                cardIdentifier: '',
            }
        }

        return {
            driverData,
            searchQuery,
          user
        }
    },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-autosuggest.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style><style>
.title-head {
    margin-bottom: 32px;
}
</style>
